// $primary: #a51616;
$primary: #00324a; /* MAIN COLOR */
$secondary: #007999; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

@import url('https://fonts.googleapis.com/css?family=Bungee+Inline|Yanone+Kaffeesatz');

h1, h2, h3, h5 {
  font-family: 'Bungee Inline', cursive;
  font-size: 30px;
}

p {
  font-family: 'Yanone Kaffeesatz', sans-serif;
  font-size: 18px;
}

.xdebug-error, .xe-warning, .flash {
  display: none;
}

nav {
  z-index: 1000;
}

.navbar {
  background: url(../img/header-bg.png) no-repeat;
  background-position: 50% 0%;
  background-attachment: scroll;
  background-color: #fafafa;
}

  .navbar-nav>li>a {
    text-align: center;
    // height: 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #00283b !important;
    margin-top: 10px;
    margin-bottom: 5px;
    border-radius: 5px;
    font-size: 1.2em;

   &:focus, &:visited{
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
    
    &:hover {
      background-color: #00283b !important;
      color: $wht !important;
      // color: #fafafa;
    }

    @media (max-width: 767px) {
          height: 0px;
          font-size: 14px;
          display: inline-block;
        
          &:focus, &:visited{
          outline: 0;
          -webkit-box-shadow: none;
          box-shadow: none;
        }
    
        &:hover {
          background-color: transparent !important;
          color: #000 !important;         
        }
      }
  }

.navbar-right {
  margin-top: 0px;
}

.navbar-toggle {
  margin: 30px 15px 8px 0px;
  border: 1px solid transparent;
  border-radius: 4px;
}

@media (max-width: 767px) {
  .navbar-toggle {
    margin-top: 20px;
  }
}

/** LOGIN FORM **/

.modal-dialog {
  max-width: 300px;
  text-align: center;
  margin: 6em auto;
  .close {
    display: none;
  }
  .modal-content {
    color: #00324a;
    h2 {
      text-align: center;
    }
  }
  input {
    border: none;
    border-bottom: 1px solid #00aeef;
    text-align: center;
  }
  button {
    background: transparent;
    color: #fafafa;
    display: block;
    border: none;
    padding: 1em 2em;
    margin: 0 auto;
    &:hover {
      background: transparent;
      color: #fff;
      box-shadow: none;
      text-shadow: 0px 0px 3px #333;
      border: none;
    }
  }
  input[type="submit"] {
    text-align: center;
    margin: 0 auto;
    border: 1px solid white;
    border-radius: 0px;
    background: #00aeef;
    color: #fafafa;
    padding: 0.5em 2em;
    display: block;
    width: 75%;
    &:hover {
      background: #00aeef;
      color: white;
      box-shadow: 0px 0px 3px #4d4d4d;
    }
  }
}

.modal-header, .modal-footer {
  background: #00324a;
  color: #fafafa;
}

input#username {
  margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
  padding: 40px 0px 20px;
  background: #00aeef;
  color: #fafafa;
  font-size: 15px;
  a {
    color: #fafafa;
    &:hover {
      color: white;
    }
  }
}

input {
  &#username, &#password {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  input {
    &#username, &#password {
      font-size: 16px;
    }
  }
}

select {
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
}

section > {
  h1, h2 {
    font-weight: bold;
    font-size: 30px;
  }
}

.btn {
  background-color: rgba(0, 40, 59, 0.8);
  border-color: #fff;
  font-size: 15px;
  padding: 15px 25px;
  color: #fafafa;

  &:focus:active {
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &:hover {
    background-color: #e7e7e7;
    color: #333;
  }
}

.logo {
  max-width: 250px;
  width: 100%;
}


@media (max-width: 360px) {
  .logo {
    max-width: 200px;
  }
  .navbar {
    background-position: 75% -26px;
  }
}

@media (max-width: 375px) {
  .logo {
    max-width: 175px;
  }
  .navbar {
    background-position: 75% -26px;
  }
}

@media (max-width: 414px) {
 
  .logo {
    max-width: 175px;
  }
  .navbar {
    background-position: 70% -39px;
  }
}



@media (max-width: 667px) and (orientation: landscape) {
  .logo {
    max-width: 200px;
  }
  .navbar {
    background-position: 65% -26px;
  }
}


@media (max-width: 568px) {
  
  .logo {
    max-width: 175px;
  }
  
  .navbar {
    background-position: 70% -39px;
  }

}

@media (max-width: 568px) and (orientation: landscape) {
  .logo {
    max-width: 175px;
  }
  .navbar {
    background-position: 70% -46px;
  }
}

@media (max-width: 736px) and (orientation: landscape) {
  .logo {
    max-width: 175px;
  }
  .navbar {
    background-position: 70% -40px;
  }
}

.padBoth {
  padding-top: 25px;
  padding-bottom: 25px;
}

.padTop {
  padding-top: 50px;
}

.padBot {
  padding-bottom: 25px;
}

.title-line {
  width: 78px;
  height: 3px;
  margin: 0 auto;
  background: #d7675a;
  margin-top: 22px;
  margin-bottom: 18px;
}

.info-form {
  p {
    font-size: 1.5em;
  }
}

@media (max-width: 768px) {
  .info-form {
    p {
      font-size: 1.2em;
    }
  }
}

.signup {
    padding-top: 100px;
  
  @media (max-width: 991px){
    padding-top: 15px; 
  }
}

.banner {
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.6) 100%, #000000 100%), url(../img/banner.jpg);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.6) 100%, #000000 100%), url(../img/banner.jpg);
  background-position: top center;
  background-attachment: fixed;
  background-size: cover;
  height: 650px;
  padding-top: 200px;
  color: #fff;

  p {
    font-size: 1.5em;
  }
}

@media (max-width: 768px){
  .banner {
  background-position: 32% 0%;
  padding-top: 100px;
  background-attachment: unset;
  
    p {
      font-size: 1.2em;
    }
  }
}

@media (min-width: 768px){
  .banner {
  background-attachment: unset;
  }
}

.social {
  padding: 100px;
  background: #007c91;
  color: #fff;
}

.banner2 {
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.6) 100%, #000000 100%), url(../img/banner2.jpg);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.6) 100%, #000000 100%), url(../img/banner2.jpg);
  background-position: top center;
  background-attachment: fixed;
  background-size: cover;
  height: 650px;
  padding-top: 200px;
  color: #fff;
  
  p {
    font-size: 1.5em;
  }
}

@media (max-width: 768px){
  .banner2 {
  background-position: 85% 0%;
  padding-top: 100px;
  background-attachment: unset;
  p {
    font-size: 1.2em;
    }
  }
}

@media (min-width: 768px){
  .banner2 {
  background-attachment: unset;
  }
}